import React, { FunctionComponent } from "react";

import "./styles.scss";

const CallOut: FunctionComponent<CallOutInterface> = ({ text }) => {

  return (
    <div
      data-testid="call-out"
      className="call-out"
    >
      <div className="text-position">{text}</div>
    </div>
  );
};

export default CallOut;

interface CallOutInterface {
  backgroundColor?: string;
  text: string;
}