import { EventType, pushEvent } from "./index";

/**
 * Video Complete
 */
export const event42 = (videoName: string, videoId: string, videoSource = "Sanity") => {
  // @ts-ignore
  window.digitalData.video = [];
  // @ts-ignore
  window.digitalData.video.push({
    videoName,
    videoId
  });

  const eventLabel = `${videoSource} - 100% -${videoName} - ${videoId}`;

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.videoCompletes,
      eventLabel: eventLabel,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    },
    subcategory: EventType.interest
  });
};
