import { EventType, pushEvent } from "./index";

/**
 * Video Start
 */
export const event41 = (videoName: string, videoId: string, videoSource = "Sanity") => {
  // @ts-ignore
  window.digitalData.video = [];
  // @ts-ignore
  window.digitalData.video.push({
    videoName,
    videoId
  });

  const eventLabel = `${videoSource} - 0% - ${videoName} - ${videoId}`;

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.videoPlays,
      eventLabel: eventLabel,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: EventType.interest
  });
};
