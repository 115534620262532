import React, { FunctionComponent } from "react";
import { RightTriangleArrow } from "../../images/icons/triangleRight";
import "./styles.scss";

interface PlayInterface {
  color?: string;
  onKeyDown?: (e:any) => void
}

const Play: FunctionComponent<PlayInterface> = ({ color, onKeyDown }) => {
  return (
    <i className="play text-center keyboard-focus" tabIndex={0} onKeyDown={onKeyDown}>
      <span className="u-sr-only">Play Video</span>
      <RightTriangleArrow color={color} />
    </i>
  );
};

export default Play;
