import React, { FunctionComponent, useEffect } from "react";

interface AnalyticsProps {
  pageName: string;
  pageType: string;
  subCategories?: ReadonlyArray<string>;
  contentType: string;
  articleName?: string;
}

const Analytics: FunctionComponent<AnalyticsProps> = props => {
  
  if(typeof window !== 'undefined'){
    window.isDigitalDataLoaded = false;
    window.isEvent1 = false;
  }

  useEffect(() => {
    // @ts-ignore
    window.digitalData.page.category.subCategory1 = "";
    // @ts-ignore
    window.digitalData.page.category.subCategory2 = "";
    // @ts-ignore
    window.digitalData.page.category.subCategory3 = "";
    if (props.subCategories) {
      if (props.subCategories.length >= 1) {
        // @ts-ignore
        window.digitalData.page.category.subCategory1 = `${props.subCategories[0]}`;
      }
      if (props.subCategories.length >= 2) {
        // @ts-ignore
        window.digitalData.page.category.subCategory2 = `${props.subCategories[1]}`;
      }
      if (props.subCategories.length >= 3) {
        // @ts-ignore
        window.digitalData.page.category.subCategory3 = `${props.subCategories[2]}`;
      }
    }
    // @ts-ignore
    if (props.articleName) window.digitalData.page.attributes.articleName = `${props.articleName}`;
    // @ts-ignore
    window.digitalData.siteInfo.internalDomain = window.location.hostname;
    // @ts-ignore
    window.digitalData.page.pageInfo.pageName = `${props.pageName}`;
    // @ts-ignore
    window.digitalData.page.pageInfo.destinationURL = window.location.href;
    // @ts-ignore
    window.digitalData.page.attributes.contentType = `${props.contentType}`;
    // @ts-ignore
    window.digitalData.page.category.pageType = `${props.pageType}`;
    window.isDigitalDataLoaded = true;

    // Only re-render if the pageName has changed. Otherwise, we risk sending multiple "trackAjaxPageLoad" events
    // when this component is re-rendered but the user is on the same page.
  }, [props.pageName]);

  return <></>;
};

export default Analytics;
