import React, { FunctionComponent } from "react";
import { PictureProps } from "./Picture.types";
import "./styles.scss";

const Picture: FunctionComponent<PictureProps> = ({
  size,
  maintainAspectRatio,
  lqip,
  loading,
  sources,
  src,
  alt,
  className,
  backgroundColor,
  inView = true
}) => {
  const aspectRatio = () => {
    return maintainAspectRatio && size?.width && size?.height ? `${(size.height / size.width) * 100}%` : null;
  };

  return (
    <picture
      style={{
        backgroundImage: `${lqip && `url(${lqip})`}`,
        paddingTop: `${aspectRatio()}`,
        display: maintainAspectRatio || backgroundColor || lqip ? "block" : "inline",
        backgroundRepeat: `no-repeat`,
        backgroundSize: `cover`,
        backgroundColor: `${backgroundColor && backgroundColor}`
      }}
    >
      {sources &&
        sources.map((source, index) => <source key={index} srcSet={source?.srcset.join(",")} media={source.media} />)}

      {inView && (
        <img
          src={src}
          alt={alt}
          className={`img-fluid ${className ? className : ""} ${maintainAspectRatio ? "picture__img" : ""}`}
          width={size?.width}
          height={size?.height}
          loading={loading}
        />
      )}
    </picture>
  );
};

export default Picture;
