import React, { FunctionComponent, useState, useEffect } from "react";
import RichTextImage from "../../components/RichTextImage";
import { ImageInterface } from "../../types/SanityTypes";
import "./styles.scss";

interface RichTextGalleryInterface {
  columnsQuantity?: number;
  isColumn?: boolean;
  picture: ReadonlyArray<ImageInterface>;
}

const RichTextGallery: FunctionComponent<RichTextGalleryInterface> = ({ columnsQuantity, isColumn, picture }) => {
  const [screenWidth, setScreenWidth] = useState(375);
  useEffect(() => {
    if (window) {
      setScreenWidth(window.innerWidth);
    }
  });
  const isMobile = screenWidth < 768;
  return (
    <ul
      className={isColumn ? "images-column" : "images-row"}
      style={
        isColumn
          ? {
              gridTemplateColumns: `repeat(${
                columnsQuantity && picture.length >= columnsQuantity && !isMobile
                  ? columnsQuantity
                  : isMobile
                  ? "1"
                  : "2"
              }, 1fr)`
            }
          : {}
      }
    >
      {picture.map((image: ImageInterface) => (
        <li key={image._key}>
          <RichTextImage data={image} />
        </li>
      ))}
    </ul>
  );
};

export default RichTextGallery;
