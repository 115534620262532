import React, { FunctionComponent } from 'react';
import { LoaderIcon } from '../../images/icons/loader';
import './styles.scss';

const Fallback: FunctionComponent = () => {
  return (
    <section className="fallback skeleton">
      <div className='loader-icon'>  
        <LoaderIcon />
      </div>
    </section>
  );
};

export default Fallback;
