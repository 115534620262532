import React, { FunctionComponent } from "react";

interface UpArrowProps {
  fill?: string;
}

export const UpArrow: FunctionComponent<UpArrowProps> = ({ fill }) => (
  <svg
    version="1.2"
    width="10"
    height="10"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 10 6"
    overflow="visible"
    xmlSpace="preserve"
  >
    <path style={{ fill: "currentcolor" }} d="M5.1,0.7L9.4,6L0.7,6L5.1,0.7z" />
  </svg>
);
