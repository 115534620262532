import React, { FunctionComponent, useState } from "react";
import Button from "../Button";
import "./styles.scss";

export interface FeedbackInterface {
  heading?: string;
  question?: string;
}

const Feedback: FunctionComponent<FeedbackInterface> = ({ heading, question }) => {
  const [showFeedbackResponse, setShowFeedbackResponse] = useState(false);

  const handleFeedbackClick = () => {
    // handle analytics click event
    setShowFeedbackResponse(true);
  };

  return (
    <>
      <span
        className={`justify-content-lg-start justify-content-between align-items-center ${
          showFeedbackResponse ? "d-none" : "d-md-flex"
        }`}
      >
        <p className="feedbackHeading mr-5 mb-0">{heading}</p>
        <Button
          variant="golden"
          className="px-4 py-2 mr-2 border-0 text-uppercase font-weight-bold feedbackButton"
          onClick={handleFeedbackClick}
          aria-label="FAQ Review - Yes"
          data-analytics-customfaq
          data-question={question}
        >
          Yes
        </Button>
        <Button
          variant="golden"
          className="px-4 py-2 border-0 text-uppercase font-weight-bold feedbackButton"
          onClick={handleFeedbackClick}
          aria-label="FAQ Review - No"
          data-analytics-customfaq
          data-question={question}
        >
          No
        </Button>
      </span>
      <p className={`feedbackResponse mr-5 mb-md-0 mt-3 ${showFeedbackResponse ? "" : "d-none"}`}>
        Thank you for your feedback
      </p>
    </>
  );
};

export default Feedback;
