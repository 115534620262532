import { EventType, pushEvent } from "./index";
import { logEvent } from "./log-events";

/**
 * Anchor Link Click Without href
 */

export const event66 = (text: string, isFaq = false) => {
  const EventData = {
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: isFaq ? EventType.accordianClicked : EventType.anchorLinkClicked,
      eventLabel: text,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    },
    subcategory: EventType.custom
  };
  pushEvent(EventData);
  logEvent("Anchor Link Click Without href", EventData);
};
