import React, { FunctionComponent, useEffect, useState } from "react";
import { event41 } from "../../analytics/event41";
import { event42 } from "../../analytics/event42";
import { event52 } from "../../analytics/event52";
import { event53 } from "../../analytics/event53";
import { event54 } from "../../analytics/event54";
import { ImageInterface } from "../../types/SanityTypes";
import initYouTube from "../../utils/initYouTube";
import Picture from "../Picture";
import Play from "../Play";
import "./styles.scss";

const PROGRESS_STEP = 25;

type YouTubeVideoComponentProps = {
  coverImage: ImageInterface;
  altVideo: string;
  video: string;
  autoplay?: boolean;
  display?: string;
  useYoutubeNativeCoverImage?: boolean;
};

const YouTubeVideoComponent: FunctionComponent<YouTubeVideoComponentProps> = props => {
  const { coverImage, video, autoplay = false, display, altVideo, useYoutubeNativeCoverImage } = props;

  const [videoData, setVideoData] = useState<any>({});
  const [showPreview, setShowPreview] = useState(true);
  const [progress, setProgress] = useState<number>(0);

  const parseYouTube = (video: string | null | undefined) => {
    if (!video) return "";
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = video.match(regExp);
    return video.startsWith("http") ? (match && match[7].length == 11 ? match[7] : "") : video;
  };

  const videoId = parseYouTube(video);

  const coverageImage = coverImage
    ? {
        src: `${coverImage.asset?.url}?w=640&h=400&fit=crop&auto=format&q=80`,
        alt: `${coverImage.alt}`,
        sources: [
          {
            srcset: [
              `${coverImage.asset?.url}?w=640&h=500&fit=crop&auto=format&q=80 1x`,
              `${coverImage.asset?.url}?w=1280&h=1000&fit=crop&auto=format&q=80 2x`
            ],
            media: `screen and (max-width: 767px)`
          },
          {
            srcset: [
              `${coverImage.asset?.url}?w=640&h=500&fit=crop&auto=format&q=80 1x`,
              `${coverImage.asset?.url}?w=1280&h=1000&fit=crop&auto=format&q=80 2x`
            ],
            media: `screen and (min-width: 768px)`
          }
        ],
        maintainAspectRatio: false,
        size: {
          width: display === "mobile" ? 360 : 640,
          height: display === "mobile" ? 640 : 400
        },
        lqip: coverImage.asset?.metadata?.lqip
      }
    : {};

  const onProgressChange = (progress: number, title: string) => {
    switch (progress) {
      case 25:
        event52(title, videoId);
        break;
      case 50:
        event53(title, videoId);
        break;
      case 75:
        event54(title, videoId);
    }
  };

  let tickerID: any = false;
  const ticker = (playerInstance: any) => {
    tickerID = setInterval(() => {
      const p = (playerInstance.getCurrentTime() / playerInstance.getDuration()) * 100;
      setProgress(Math.floor(p / PROGRESS_STEP) * PROGRESS_STEP);
    }, 1000);
  };

  useEffect(() => {
    initYouTube();
    return clearInterval(tickerID);
  }, []);

  useEffect(() => {
    onProgressChange(progress, videoData.title);
  }, [progress]);

  const onPlayerStateChange = (e: { data: number; target: any }) => {
    if (e.data !== 1) {
      clearInterval(tickerID);
      tickerID = false;
    } else if (e.data === 1) {
      ticker(e.target);
    }
    if (e.data === 0) {
      event42(e.target.videoTitle, videoId);
    }
  };

  const handlePlayButtonClick = () => {
    setTimeout(() => {
      window.YT.ready(() => {
        new window.YT.Player(`player-${videoId}`, {
          videoId: videoId,
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange
          }
        });
      });
    }, 1000);
  };

  useEffect(() => {
    if (autoplay) {
      handlePlayButtonClick();
    }
  }, []);

  const onPlayerReady = (e: {
    target: {
      playVideo: () => void;
      getVideoData: any;
      videoTitle: string;
    };
  }) => {
    e.target.playVideo();
    setVideoData(e.target.getVideoData());
    event41(e.target.videoTitle, videoId);
    setShowPreview(false);
  };

  return (
    <React.Fragment>
      <div className={`video-player__wrapper ${display}`}>
        <div className={`video-player__preview ${!showPreview && "video-player__preview--hidden"}  ${display}`}>
          <button className="video-player__play" onClick={handlePlayButtonClick}>
            <Play />
          </button>
          {!useYoutubeNativeCoverImage && coverImage ? (
            <Picture
              src={coverageImage.src}
              alt={coverageImage.alt}
              className="video-player__cover"
              sources={coverageImage.sources}
              size={coverageImage.size}
              maintainAspectRatio={coverageImage.maintainAspectRatio}
              loading="lazy"
              lqip={coverageImage.lqip}
            />
          ) : (
            <Picture
              src={"https://img.youtube.com/vi/" + videoId + "/hqdefault.jpg"}
              alt={altVideo ?? ""}
              className="video-player__cover"
              loading="lazy"
            />
          )}
        </div>
        <div className={`video-player__video  ${display}`}>
          <div id={`player-${videoId}`} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default YouTubeVideoComponent;
