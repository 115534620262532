import { EventType, pushEvent } from "./index";

export const videoProgress = (videoName: string, videoId: string, progress: number, videoSource: string) => {
  // @ts-ignore
  window.digitalData.video = [];
  // @ts-ignore
  window.digitalData.video.push({
    videoName,
    videoId
  });

  const eventLabel = `${videoSource} - ${progress}% - ${videoName} - ${videoId}`;

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.videoProgress,
      eventLabel: eventLabel,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    },
    subcategory: EventType.read
  });
};
