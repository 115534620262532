import React, { FunctionComponent } from "react";
import RichTextImage from "../RichTextImage";
import RichText from "../RichText";
import { Link } from "../Link";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ImageInterface, PortableText } from "../../types/SanityTypes";
import "./styles.scss";
import Container from "react-bootstrap/Container";

interface ImageTextBlockInterface {
  data?: {
    image: ImageInterface;
    text: PortableText;
    textPosition: string;
    imageType: boolean;
    heroCTALabel?: string;
    isExternalLink?: boolean;
    externalCtaLink?: string;
    ctaLink: {
      slug?: {
        current: string;
      },
      parentSection: {
        slug?: {
          current: string;
        },
      };
    };
  };
  imageAsset?: {
    _rawImage: ImageInterface;
    image?: ImageInterface;
    _rawText: PortableText;
    text?: PortableText;
    textPosition: string;
    imageType: boolean;
  };
  title?: string;
}

const ImageTextBlock: FunctionComponent<ImageTextBlockInterface> = ({ data, imageAsset, title }) => {
  const imageType = data?.imageType || imageAsset?.imageType;
  const textPosition = data?.textPosition || imageAsset?.textPosition;
  const image = (data?.image || imageAsset?._rawImage || imageAsset?.image) as ImageInterface;
  const text = (data?.text || imageAsset?._rawText || imageAsset?.text) as PortableText;
  const imageTypeClass = imageType === true ? "about-us-image-text" : "image-text";
  const heroCTALabel = data?.heroCTALabel;
  const isExternalLink = data?.isExternalLink;
  const externalCtaLink = data?.externalCtaLink;
  const ctaLink = data?.ctaLink;

  const renderCta = () => {
    return (
      <>
        {isExternalLink && heroCTALabel ? (
          <Link
            to={externalCtaLink}
            className="d-inline-block text-uppercase"
            type="external"
            rel="noreferrer"
            title="Links opens in a new window"
            data-analytics-event78
            aria-label={heroCTALabel}
          >
            {heroCTALabel}
          </Link>
        ) : (
          ctaLink &&
          heroCTALabel && (
            <Link
              to={
                ctaLink?.parentSection
                  ? `${ctaLink.parentSection?.slug.current}/${ctaLink.slug.current}`
                  : `${ctaLink.slug?.current}`
              }
              className="c-link__button c-link__button--secondary d-inline-block text-uppercase"
            >
              {heroCTALabel}
            </Link>
          )
        )}
      </>
    )
  }

  const renderBlockOrder = () => {
    if (textPosition === "left") {
      return (
        <>
          {title && <h2>{title}</h2>}
          <div className="order-lg-2 col-md-12 col-lg-6">
            <RichTextImage data={image} />
          </div>
          <div className="order-lg-1 col-lg-6 cold-md-12" data-testid="text-left">
            <RichText data={text} />
            {renderCta()}
          </div>
        </>
      );
    }
    return (
      <>
        {title && <h2>{title}</h2>}
        <div className="col-md-12 col-lg-6">
          <RichTextImage data={image} type={imageTypeClass} />
        </div>
        <div data-testid="text-right" className="col-md-12 col-lg-6">
          <RichText data={text} />
          {renderCta()}
        </div>
      </>
    );
  };

  return (
    <div className="image-text-block">
      {imageAsset ? (
        <Container fluid>
          <Row className="image-asset-block">{renderBlockOrder()}</Row>
        </Container>
      ) : (
        <Row>{renderBlockOrder()}</Row>
      )}
    </div>
  );
};

export default ImageTextBlock;
