import React, { FunctionComponent } from "react";
import { Cart } from "../../images/icons/cart";
import { Link } from "../Link";
import "./styles.scss";

export interface ProductLinkInterface {
  link: string;
  name: string;
}

const ProductLink: FunctionComponent<ProductLinkInterface> = ({ link, name }) => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <Link className="product-link" to={link}>
      <Cart /> {name}
    </Link>
  );
};

export default ProductLink;
