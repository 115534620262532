import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Logo } from "../../images/icons/logo";
import { MagnifyIcon } from "../../images/icons/magnifyIcon";
import { Link } from "../Link";
import "./styles.scss";
import OutsideAlerter from "./OutsideAlerter";
import { ImageInterface } from "../../types/SanityTypes";
import Picture from "../Picture";
import normalize from "normalize-path";
// import { LocalizedContext } from "../../services/LocalizedContextService";

const Header = ({ data, hideNavigationCtaList }: any) => {
  const [screenWidth, setScreenWidth] = useState(375);
  const [pathName, setPathName] = useState("");
  // const { sanityAccessibility } = useContext(LocalizedContext);
  const items = [...data.sanityHeader.navItems];
  const getNavigationUrl = item => {
    if (["SanityCollectionLandingPage", "SanitySearchListingPage"].includes(item.landingPage?.__typename)) {
      return item.landingPage?.slug
        ? `${item.landingPage.parentPage?.slug.current}/${item.landingPage.slug.current}`
        : item.path;
    } else {
      return normalize(`/${item.landingPage?.slug ? item.landingPage.slug.current : item.path}/`);
    }
  };

  const closeNavDropDown = () => {
    document.getElementById("menu-form")?.reset();
    const checkboxes = document.getElementsByName("navigation");
    checkboxes.forEach(item => {
      item.checked = false;
      const label = document.querySelector(`[for=${item.id}]`);
      label && label.setAttribute("aria-expanded", "false");
    });
  };

  const handleClick = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.setAttribute("aria-expanded", "true");
    const id = e.target.getAttribute("for");
    const checkboxes = document.getElementsByName("navigation");
    checkboxes.forEach(item => {
      if (item.id !== id) {
        item.checked = false;
        const label = document.querySelector(`[for=${item.id}]`);
        label && label.setAttribute("aria-expanded", "false");
      }
    });
    const form = document.getElementById("menu-form");
    if (form) {
      form.scrollTop = 0;
    }
  };

  const handleKeyPress = (e: any) => {
    e.preventDefault();
    if (e.keyCode === 13 || e.keyCode === 0) {
      const id = e.target.getAttribute("for");
      const target = document.getElementById(id);
      target.checked = !target.checked;
      e.target.setAttribute("aria-expanded", target.checked ? "true" : "false");
      const checkboxes = document.getElementsByName("navigation");
      checkboxes.forEach(item => {
        if (item.id !== id) {
          item.checked = false;
          const label = document.querySelector(`[for=${item.id}]`);
          label && label.setAttribute("aria-expanded", "false");
        }
      });
    }
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const label = e.target.nextElementSibling;
    label && label.setAttribute("aria-expanded", e.target.checked ? "true" : "false");
  };

  const handleLogoClick = () => {
    const ham = document.getElementById("hamburger");
    if (ham) ham.checked = false;
  };

  const getSources = (image: ImageInterface, w: number, h: number) => [
    {
      srcset: [
        `${image.asset?.url}?w=${w}&h=${h}&fit=crop&auto=format&q=80 1x`,
        `${image.asset?.url}?w=${w}&h=${h}&fit=crop&auto=format&q=80 2x`
      ],
      media: `(max-width: 1023px)`
    },
    {
      srcset: [
        `${image.asset?.url}?w=${w * 1.4}&h=${h * 1.4}&fit=crop&auto=format&q=80 1x`,
        `${image.asset?.url}?w=${w * 1.4}&h=${h * 1.4}&fit=crop&auto=format&q=80 2x`
      ],
      media: `(min-width: 1024px)`
    }
  ];

  const handleCloseDropDown = (e: any) => {
    if (e.keyCode === 27) {
      closeNavDropDown();
    }
  };

  useEffect(() => {
    if (window) {
      setScreenWidth(window.innerWidth);
      setPathName(window.location.pathname);
    }
    document.addEventListener("keydown", handleCloseDropDown);
    return () => {
      document.removeEventListener("keydown", handleCloseDropDown);
    };
  }, []);

  const handleNavigate = (e: any, link: string) => {
    if (e.keyCode === 13) {
      navigate(link);
    }
  };

  const navigateToLink = (event) => {
    if (event.target.closest('a')) {
      return;
    }
    const link = event.currentTarget.querySelector('a');
    if (link) {
      link.click();
    }
  };

  const handleKeyPressToLink = (event) => {
    if (event.key === 'Enter') {
      navigateToLink(event);
    }
  };

  return (
    <>
      <a className="skip-to-content" href="#mainContent">
        Skip To Content
      </a>
      <header className="header">
        <nav role="navigation" aria-label="menu">
          <Link
            to="/"
            aria-label="Tresemme logo"
            className="header-logo"
            onClick={handleLogoClick}
            data-analytics-event78
          >
            <Logo />
          </Link>
          <input type="checkbox" id="hamburger" className="header__hamburger" />
          <label htmlFor="hamburger" className="header__hamburger-control">
            <span />
            <span />
            <span />
            <p>Menu</p>
          </label>
          <form action="" id="menu-form">
            <OutsideAlerter closeNavDropDown={closeNavDropDown}>
              <ul className="navigation" role="menubar">
                {items.map((item, index) => (
                  <li className="navigation-item" key={index} role="menuitem">
                    {item.navL2?.length ? (
                      <>
                        <input
                          type="checkbox"
                          value="1"
                          id={item.navL1.name.split(" ").join("")}
                          name="navigation"
                          className="navigation-checkbox"
                          onChange={handleCheckboxChange}
                        />
                        <label
                          htmlFor={item.navL1.name.split(" ").join("")}
                          tabIndex={0}
                          onKeyPress={handleKeyPress}
                          onClick={handleClick}
                          //aria-expanded="false"
                          aria-haspopup="true"
                          aria-label={item.navL1.name}
                          // aria-control={`subnav-${index+1}`}
                        >
                          {item.navL1.name}
                        </label>
                        {/* data-subnav={index+1} id={`subnav-${index+1}`} */}
                        <div className="navigation-list" role="menu" aria-label={item.navL1.name}>
                          <div
                            className={`navigation-list-wrapper ${
                              item.navL2.length === 5 && "navigation-list-wrapper--full"
                            }`}
                            role="menubar"
                          >
                            <ul
                              className={`navigation-list-listing ${
                                item.navL2.length === 5 && "navigation-list-listing--full"
                              }`}
                            >
                              {item.navL2.slice(0, item.navL2.length === 5 ? 5 : 4).map((item, i) =>
                                item.externalLink ? (
                                  <li
                                    key={i}
                                    className="navigation-link"
                                    role="menuitem"
                                    aria-label={item.name}
                                    data-analytics-event78
                                  >
                                    <a
                                      href={item.externalLink}
                                      aria-label={item.name}
                                      data-analytics-event78
                                      tabIndex={-1}
                                    >
                                      <div className="navigation-card">
                                        {item.image && (
                                          <div className="navigation-card-image">
                                            <Picture
                                              src={item.image.src}
                                              alt={item.image.alt || `Image for ${item.name}`}
                                              sources={getSources(item.image, 300, 300)}
                                              size={{
                                                width: 300,
                                                height: 300
                                              }}
                                              maintainAspectRatio={false}
                                              loading="lazy"
                                              lqip={item.image.asset?.metadata?.lqip.lqip}
                                            />
                                          </div>
                                        )}
                                        <div className="navigation-card-content">
                                          <span
                                            className="navigation-card_title keyboard-focus"
                                            aria-label={`${item.name} item`}
                                            onKeyDown={e => handleNavigate(e, getNavigationUrl(item))}
                                            tabIndex={0}
                                          >
                                            {item.name}
                                          </span>
                                          <p className="navigation-card_description">{item.description}</p>
                                        </div>
                                      </div>
                                    </a>
                                  </li>
                                ) : (
                                  <li
                                    key={i}
                                    className="navigation-link"
                                    role="menuitem"
                                    aria-label={item.name}
                                    data-analytics-event78
                                  >
                                    <div
                                      className="navigation-card"
                                      tabIndex={-2}
                                      onClick={navigateToLink}
                                      onKeyPress={handleKeyPressToLink}
                                    >
                                      {item.image && (
                                        <div className="navigation-card-image">
                                          <Picture
                                            src={item.image.src}
                                            alt={item.image.alt || `Image for ${item.name}`}
                                            sources={getSources(item.image, 300, 300)}
                                            size={{
                                              width: 300,
                                              height: 300
                                            }}
                                            maintainAspectRatio={false}
                                            loading="lazy"
                                            lqip={item.image.asset?.metadata?.lqip.lqip}
                                          />
                                        </div>
                                      )}
                                      <div className="navigation-card-content">
                                        <span
                                          className="navigation-card_title keyboard-focus"
                                          aria-label={`${item.name} item`}
                                          onKeyDown={e => handleNavigate(e, getNavigationUrl(item))}
                                          tabIndex={0}
                                        >
                                          <Link
                                            to={getNavigationUrl(item)}
                                            aria-label={item.name}
                                            data-analytics-event78
                                            tabIndex={-1}
                                          >
                                            {item.name}
                                          </Link>
                                        </span>
                                        <p className="navigation-card_description">{item.description}</p>
                                      </div>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                            {item.navL2.length > 5 ? (
                              <div className="navigation-card-stack">
                                <ul className="navigation-list-listing" role={"treegrid"}>
                                  {item.navL2.slice(4).map((item, i) => (
                                    <li
                                      className="navigation-link navigation-secondary"
                                      key={i}
                                      role="menuitem"
                                      aria-label={item.name}
                                      data-analytics-event78
                                    >
                                      <div
                                        className="navigation-card"
                                        onClick={navigateToLink}
                                        onKeyPress={handleKeyPressToLink}
                                      >
                                        {item.image && (
                                          <div className="navigation-card-image">
                                            <Picture
                                              src={item.image.src}
                                              alt={item.image.alt || `Image for ${item.name}`}
                                              sources={getSources(item.image, 300, 300)}
                                              size={{
                                                width: 300,
                                                height: 300
                                              }}
                                              maintainAspectRatio={false}
                                              loading="lazy"
                                              lqip={item.image.asset?.metadata?.lqip.lqip}
                                            />
                                          </div>
                                        )}
                                        <div className="navigation-card-content">
                                          <span
                                            className="navigation-card_title keyboard-focus"
                                            aria-label={`${item.name} item`}
                                            onKeyDown={e => handleNavigate(e, getNavigationUrl(item))}
                                            tabIndex={0}
                                          >
                                            <Link
                                              to={getNavigationUrl(item)}
                                              aria-label={item.name}
                                              data-analytics-event78
                                              tabIndex={-1}
                                            >
                                              {item.name}
                                            </Link>
                                          </span>
                                          <p className="navigation-card_description">{item.description}</p>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : null}
                          </div>
                          {(item.navL1.landingPage?.slug || item.navL1.path) && (
                            <div className="navigation-footer">
                              <Link
                                className="navigation-view-more"
                                to={
                                  item.navL1.landingPage?.slug ? item.navL1.landingPage.slug.current : item.navL1.path
                                }
                                data-analytics-event78
                                aria-label="View all"
                              >
                                View all
                              </Link>
                            </div>
                          )}
                        </div>
                      </>
                    ) : item.navL1.path || item.navL1.externalLink ? (
                      <Link
                        to={item.navL1.path || item.navL1.externalLink}
                        data-analytics-event78
                        aria-label={item.navL1.name}
                        type={item.navL1.path ? "internal" : "external"}
                        target={item.navL1.path ? "_self" : "_blank"}
                      >
                        {item.navL1.name}
                      </Link>
                    ) : null}
                  </li>
                ))}
              </ul>
            </OutsideAlerter>
          </form>
          <div className={`navigation--right ${hideNavigationCtaList && "navigation--right__cta-hided"}`}>
            <ul className="navigation">
              {/*<li>*/}
              {/*  <Link to="#" asButton="transparent" title="Message" data-analytics-event78 aria-label="Chatbot">*/}
              {/*    <MsgIcon tabIndex={-1} aria-label="message icon" transparent="true" />*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link to="/search/" asButton="transparent" title="Search" data-analytics-event78 aria-label="Search">
                  <MagnifyIcon tabIndex={-1} aria-label="search icon" transparent="true" />
                  <span className="navigation-title ml-lg-2">Search</span>
                </Link>
              </li>
            </ul>
          </div>
          
          <div className="navigation-cta-links-wrapper">
              <ul className={`navigation-cta-links d-flex justify-content-between ${pathName === '/us/en/' && "navigation-cta-links-home"}`}>
                <li className="flex-grow-1 d-flex align-items-stretch">
                  <Link
                    className={`prod-test ${pathName !== '/us/en/' && "get-styled-button"}`}
                    to={process.env.NODE_ENV !== "production" ? "/virtualstyler" : "/us/en/virtualstyler"}
                    asButton="golden"
                    type="external"
                    rel="noreferrer"
                    data-analytics-event78
                    title={`Get Styled`}
                    aria-label={`Get Styled`}
                  >
                    Get Styled
                  </Link>
                </li>
                <li className="flex-grow-1 d-flex align-items-stretch">
                  <Link
                    to="/sign-up"
                    asButton="golden"
                    rel="noreferrer"
                    data-analytics-event78
                    title={`Sign Up`}
                    aria-label={`Sign Up`}
                  >
                    Sign Up
                  </Link>
                </li>
              </ul>
          </div>
          
        </nav>
      </header>
    </>
  );
};

export default Header;
