import React, { FunctionComponent } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import ContextAwareAccordionToggle from "../ContextAwareAccordionToggle";
import Feedback from "../Feedback";

import "./styles.scss";

export interface FAQBlockInterface {
  faqItems: FaqItem[];
  heading?: string;
}

export type FaqItem = {
  question: string;
  _rawAnswer?: Record<string, unknown>;
  answer?: any;
  _key: string;
};

const FAQBlock: FunctionComponent<FAQBlockInterface> = ({ faqItems }) => {
  const renderItems = () => {
    return faqItems.map((item, index) => {
      return (
        <Card key={index} data-testid="faq-card" aria-label={item.question} data-analytics-event3>
          <ContextAwareAccordionToggle eventKey={item._key}>{item.question}</ContextAwareAccordionToggle>
          <Accordion.Collapse eventKey={item._key}>
            <Card.Body className="px-4 pb-3 pb-md-4">
              <BlockContent blocks={item._rawAnswer || item.answer} />
              <Feedback heading="Did you find what you are looking for?" question={item.question} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    });
  };

  return (
    <div data-testid="faq-block" className="faq-block">
      <Accordion defaultActiveKey={faqItems && faqItems.length ? faqItems[0]["_key"] : ""}>{renderItems()}</Accordion>
    </div>
  );
};

export default FAQBlock;
