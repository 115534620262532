import React, { FunctionComponent, useContext } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { event66 } from "../../analytics/event66";

const ContextAwareAccordionToggle: FunctionComponent<{ eventKey: string; cb: () => void }> = ({
  children,
  eventKey,
  cb
}) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    cb && cb();
    event66(children, true);
  }) as any;

  const isCurrentEventKey = currentEventKey === eventKey;

  const handleEnter = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      decoratedOnClick(event);
    }
  };

  return (
    <Accordion.Toggle
      role="button"
      as={Card.Header}
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      onClick={decoratedOnClick}
      onKeyDown={handleEnter}
      eventKey={eventKey}
      tabIndex={0}
      className="pl-3 pr-5"
    >
      {children}
    </Accordion.Toggle>
  );
};

export default ContextAwareAccordionToggle;
