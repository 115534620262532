import React from "react";

export const MagnifyIcon = (props: any) => (
  <svg width="27" height="27" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.91881 2.93041C4.80408 1.0407 7.31065 0 9.97673 0C12.6428 0 15.1495 1.0407 17.0346 2.93041C20.2525 6.15579 20.8094 11.0535 18.7061 14.8582L23.2091 19.3718C24.2636 20.4289 24.2636 22.1489 23.2091 23.2059C22.6982 23.7181 22.019 24 21.2965 24C20.5741 24 19.8949 23.7181 19.384 23.2059L14.9011 18.7124C13.4164 19.5591 11.7311 20.0098 9.97673 20.0098C7.31057 20.0098 4.804 18.9692 2.91881 17.0794C-0.972939 13.1785 -0.972939 6.83131 2.91881 2.93041ZM4.68564 15.3084C6.09897 16.7249 7.978 17.5051 9.97664 17.5051C11.9753 17.5051 13.8544 16.7249 15.2676 15.3084C18.185 12.3841 18.185 7.62572 15.2676 4.70148C13.8543 3.28483 11.9753 2.5047 9.97664 2.5047C7.978 2.5047 6.09889 3.28483 4.68564 4.70148C1.76827 7.6258 1.76827 12.3841 4.68564 15.3084Z"
      style={props.transparent ? {} : { fill: props.color || "currentColor" }}
    />
  </svg>
);
