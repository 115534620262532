import React, { FunctionComponent } from "react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface } from "../../types/SanityTypes";
import "./styles.scss";

interface RichTextImageInterface {
  data: ImageInterface;
}

const RichTextImage: FunctionComponent<RichTextImageInterface> = ({ data }) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const isPngImage = data.asset.mimeType === "image/png";

  const displayPlaceholder = () => {
    if (data.asset?.metadata && !isPngImage)
      return {
        background: `url(${data.asset?.metadata.lqip})`,
        backgroundSize: "cover"
      };
  };

  const sources = [
    {
      srcset: [
        `${urlBuilder.image(data).width(800).height(800).auto("format")?.url()} 1x`,
        `${urlBuilder.image(data).width(1200).height(1200).auto("format")?.url()} 2x`
      ],
      media: `(max-width: 767px)`
    },
    {
      srcset: [
        `${urlBuilder.image(data).width(500).height(500).auto("format")?.url()} 1x`,
        `${urlBuilder.image(data).width(1000).height(1000).auto("format")?.url()} 2x`
      ],
      media: `(min-width: 768px)`
    }
  ];

  return (
    <div className="rich-text-image" data-testid="rich-text-image">
      <figure className="placeholder" style={displayPlaceholder()}>
        {sources.map((source, index) => (
          <source key={index} srcSet={source?.srcset.join(",")} media={source.media} />
        ))}
        <img
          src={
            urlBuilder
              .image(data as ImageInterface)
              .auto("format")
              ?.url() as string
          }
          alt={data?.alt}
          loading="lazy"
          className="image-text"
        />
      </figure>
    </div>
  );
};

export default RichTextImage;
