export const emailValidation = (email: string) => {
  const emailRE = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  return emailRE.test(email);
};

export const zipCodeValidation = (phone: string) => {
  const phoneRE = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return phoneRE.test(phone);
};

export const nameValidation = (name: string) => {
  const nameRE = /(^[a-zA-Z\s]{1,30}$)/;
  return nameRE.test(name);
};

export const dateValidation = (data: string) => {
  return data.length > 0;
};

export const setCookie = ({ cookieKey, cookieValue, expiryDays = 30 }: any) => {
  const dateNow = new Date();
  const cookieExpiryDate = dateNow.getTime() + expiryDays * 24 * 60 * 60 * 1000;
  document.cookie = `${cookieKey}=${cookieValue};expires=${cookieExpiryDate.toString()};path=/`;
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const smoothScroll = (targetPosition: number) => {
  window.scrollTo({
    top: targetPosition,
    behavior: "smooth"
  });

  return new Promise<void>((resolve, reject) => {
    const failed = setTimeout(() => {
      reject();
    }, 2000);

    const scrollHandler = () => {
      if (self.pageYOffset === targetPosition) {
        window.removeEventListener("scroll", scrollHandler);
        clearTimeout(failed);
        resolve();
      }
    };
    window.addEventListener("scroll", scrollHandler);
  });
};
