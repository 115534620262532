import React, { FunctionComponent, useContext } from "react";
import Accessibility from "../Accessibility";
// import { LocalizedContext } from "../../services/LocalizedContextService";
export interface ExternalLinkInterface {
  location?: string;
  link: string;
  name: string;
  nofollow?: boolean;
  noopener?: boolean;
  markKey: string;
}

const ExternalLink: FunctionComponent<ExternalLinkInterface> = ({
  location,
  link,
  name,
  nofollow,
  noopener,
  markKey
}) => {
  const noFollow = nofollow ? "nofollow" : "";
  // const { sanityAccessibility } = useContext(LocalizedContext);

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className="external-link"
      href={link}
      rel={`noreferrer ${!noopener} ${noFollow}`}
      key={markKey}
      aria-label={`${name}`}
      title={`${name}`}
    >
      {" "}
      {name}
      {location ? <Accessibility location={location} openNewWindow={false} /> : <Accessibility openNewWindow={false} />}
    </a>
  );
};

export default ExternalLink;
