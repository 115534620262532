import React, { FunctionComponent } from "react";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { RichTextSerializers } from "../../utils/richTextSerializers";
import "./styles.scss";

interface RichTextInterface {
  data?: ReadonlyArray<unknown>;
  colorTheme?: string;
  pageSlug?: string;
}

const RichText: FunctionComponent<RichTextInterface> = ({ data, colorTheme, pageSlug }) => {
  return (
    <section className="rich-text">
      <BlockContent blocks={data} serializers={RichTextSerializers(pageSlug, colorTheme)} />
    </section>
  );
};

export default RichText;
