import normalize from "normalize-path";

export const stagingOrProd = (staging: string, prod: string) =>
  process.env.BUILD_ENV && process.env.BUILD_ENV != "production" ? staging : prod;

/**
 * 1. If a document _id is specified:
 *     1. If this document is in the base language, use the slug as is.
 *     2. Otherwise, prefix the path with the language code (e.g. "/fr").
 * 2. Normalize the slug, by adding a leading and trailing slash, then replacing any duplicate slashes ("//") with a
 * single slash.
 *
 * @param slug Sanity document slug
 * @param _id Sanity document ID. Optional.
 */
export const getLocalizedPath = (slug: string, _id?: string) => {
  const path = slug ? slug.split("#") : ['/'];
  let localizedPath = path[0];

  if (_id && _id.indexOf("i18n") >= 0) {
    const matches = _id.match(/\.([^.]+)\.?$/);
    if (matches) {
      const language = matches[1].split("_")[0]?.toLowerCase();
      localizedPath = `${language}/${localizedPath}`;
    }
  }
  return normalize(`/${localizedPath}/${path[1] ? `#${path[1]}` : ""}`, false);
};
