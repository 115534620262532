import { useStaticQuery, graphql } from "gatsby";

/**
 * Site metadata is defined in gatsby-config
 *
 * Language only needs to be passed if you are retrieving config values that change depending on the language being
 * used. These are defined under additionalLanguagesConfig below.
 *
 * For example:
 *
 * ```
 * const siteMetadata = {
 *   apiKey: "abc123"
 *   additionalLanguagesConfig: [
 *     {
 *       language: "fr",
 *        apiKey: "xyz789"
 *     },
 *     {
 *       language: "es",
 *       apiKey: "pqv456"
 *     }
 *   ]
 * }
 * ```
 *
 * Then:
 *
 * `const { apiKey } = useSiteMetadata("fr")  // apiKey is "xyz789"`
 */
export const useSiteMetadata = (language?: string) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            analyticsAdobeAnalyticsId
            analyticsAdobeLaunchScriptName
            analyticsCountry
            analyticsGoogleAnalyticsId
            analyticsLanguage
            analyticsLocalBrand
            bazaarvoiceEnv
            binConstCo
            binCartWire
            contactUsBrandId
            contactUsEnv
            contactUsFormType
            contactUsLocale
            prmApiUrl
            prmBrandCode
            prmCampaignId
            epsilonUrl
            epsilonDataSourceId
            epsilonSignUpCCID
            epsilonBrandCCID
            epsilonCorporateCCID
            reCaptchaSiteKey
            htmlLang
            kritiqueApiKey
            kritiqueBrandId
            kritiqueLocaleId
            kritiqueRegion
            kritiqueSiteSource
            locale
            isoLanguage
            isoCountry
            extendedProfileApiUrl
            miappiClientId
            oneTrustDataDomainScriptId
            cookieQscriptURL
            cookieQlang
            cookieQORG
            sanityId
            sanityDataset
            siteUrl
            smartLabel
            youtubeApiIframe
            evidonCompanyID
            evidonOCID
            additionalLanguagesConfig {
              language
              analyticsLanguage
              contactUsLocale
              htmlLang
              epsilonDataSourceId
              epsilonBrandCCID
              kritiqueLocaleId
              kritiqueSiteSource
              isoLanguage
              locale
              country
              languageString
            }
          }
        }
      }
    `
  );

  /**
   * Override values in siteMetadata using the additionalLanguagesConfig object for a given language.
   */
  if (language) {
    //@ts-ignore
    site.siteMetadata.additionalLanguagesConfig.forEach(additionalLanguageConfig => {
      if (language === additionalLanguageConfig.language) {
        //@ts-ignore
        const { language, ...languageSpecificConfig } = additionalLanguageConfig;
        site.siteMetadata = { ...site.siteMetadata, ...languageSpecificConfig };
      }
    });
  }

  site.siteMetadata.siteName = "Tresemme US";

  return site.siteMetadata;
};
