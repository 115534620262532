import React, { FunctionComponent } from "react";
import classNames from "classnames";

import "./styles.scss";

interface SectionInterface {
  children: any;
  theme?: any;
  heading?: string;
  subHeading?: string;
  className?: string;
  isWide?: boolean;
  beforeHeading?: string;
  afterHeading?: string;
}

const Section: FunctionComponent<SectionInterface> = ({
  children,
  theme,
  heading,
  subHeading,
  beforeHeading,
  afterHeading,
  className,
  isWide = false,
  ...props
}) => {
  const themeColor = theme?.title?.toLowerCase();

  return (
    <section className={classNames("c-section", className, `c-section--${themeColor || "secondary"}`)} {...props}>
      {heading && (
        <header>
          <h2 className="text-headline-1 c-section__heading">
            {beforeHeading && <span className="c-section__heading--before">{beforeHeading}</span>}
            {heading}
            {afterHeading && <span className="c-section__heading--after">{afterHeading}</span>}
          </h2>
          {subHeading && <h3 className="c-section__subheading">{subHeading}</h3>}
        </header>
      )}
      <div className={isWide ? "" : "container"}>{children}</div>
    </section>
  );
};

export default Section;
