import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";

import { FunctionComponent, ReactNode } from "react";
import HeaderNew from "../HeaderNew/index";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { LocalizedContextInterface } from "../../types/SanityTypes";
import { LocalizedContext } from "../../services/LocalizedContextService";
import Analytics from "../Analytics/DigitalData";
const Footer = loadable(() => import("../Footer"));
import Fallback from "../FallbackLoader";
import BackToTop from "../BackToTop";
import "./styles.scss";

type LayoutProps = {
  children: ReactNode;
  pageName: string;
  pageType: string;
  localizedContext: LocalizedContextInterface;
  parentPages?: ReadonlyArray<string>;
  contentType?: string;
  hideNewsLetterFooterBlock?: boolean;
  hideNewsletterPopUp?: boolean;
  hideNavigationCtaList?: boolean;
};

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  localizedContext,
  hideNewsLetterFooterBlock,
  hideNewsletterPopUp,
  pageName,
  contentType,
  pageType,
  parentPages,
  hideNavigationCtaList
}) => {
  const { htmlLang } = useSiteMetadata(localizedContext.language);
  const [enableAnalytics, setEnableAnalytics] = useState(false);

  useEffect(() => {
    const urlWeb = new URL(window.location.href);
    const allowScript = urlWeb.searchParams.get("enableAnalytics");
    setEnableAnalytics(allowScript !== "false");
  }, []);

  return (
    <LocalizedContext.Provider value={localizedContext}>
      <div>
        <Helmet>
          <html lang={htmlLang} />
          <script id="mikmak-script">
            {`
(function(e,d){try{var a=window.swnDataLayer=window.swnDataLayer||{};a.appId=e||a.appId,a.eventBuffer=a.eventBuffer||[],a.loadBuffer=a.loadBuffer||[],a.push=a.push||function(e){a.eventBuffer.push(e)},a.load=a.load||function(e){a.loadBuffer.push(e)},a.dnt=a.dnt!=null?a.dnt:d;var t=document.getElementsByTagName("script")[0],n=document.createElement("script");n.async=!0,n.src="//wtb-tag.mikmak.ai/scripts/"+a.appId+"/tag.min.js",t.parentNode.insertBefore(n,t)}catch(e){console.log(e)}}("65c10dba8f70c40e557a08e3", false));`}
          </script>
          <meta name="ps-key" content="1750-5db31b2e0a36b30033a103a3" />
        </Helmet>
        {enableAnalytics && (
          <Analytics
            pageName={pageName}
            pageType={pageType}
            subCategories={parentPages}
            contentType={contentType || pageName}
            articleName={pageType == "article" ? pageName : ""}
          />
        )}
        <HeaderNew data={localizedContext} hideNavigationCtaList={hideNavigationCtaList} />
        <main className={`main-content ${hideNavigationCtaList && "main-content__nav-cta-hided"}`}>
          <div id="mainContent">{children}</div>
          <BackToTop />
        </main>
        <Footer
          data={localizedContext}
          hideNewsLetterFooterBlock={hideNewsLetterFooterBlock}
          hideNewsletterPopUp={hideNewsletterPopUp}
          fallback={<Fallback />}
        />
      </div>
    </LocalizedContext.Provider>
  );
};

export default Layout;
