import * as React from "react";

export const Cart = (props: any) => (
  <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="none"
      stroke="#000"
      strokeWidth={2}
      d="M5 5h17l-2 9H7L4 2H0m7 12 1 4h13m-2 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2ZM9 23a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
    />
  </svg>
);
