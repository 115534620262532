/* eslint-disable react/display-name */
import React, { useContext } from "react";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import ImageTextBlock from "../components/ImageTextBlock";
import RichTextGallery from "../components/RichTextGallery";
import RichTextImage from "../components/RichTextImage";
import TimePeriodBlock from "../components/TimePeriodBlock";
import FAQBlock, { FaqItem } from "../components/FAQBlock";
import EnumeratedBlock, { BlockItem } from "../components/enumeratedContent";
// import SocialWall, { SocialWallInterface } from "../components/SocialWall";
import { ImageInterface } from "../types/SanityTypes";
import { Link } from "../components/Link";
import ExternalLink from "../components/ExternalLink";
import ProductLink from "../components/ProductLink";
import { LocalizedContext } from "../services/LocalizedContextService";
import YouTubeVideoComponent from "../components/YoutubeVideoComponent";
import CallOut from "../components/Callout";

type MultipleImageNode = {
  _key: string;
  _type: string;
  columnsQuantity?: number | undefined;
  isColumn?: boolean | undefined;
  picture: ReadonlyArray<ImageInterface>;
};

type ImageTextNode = {
  image: ImageInterface;
  text: readonly Record<string, unknown>[];
  textPosition: string;
  imageType: boolean;
};

type TimePeriodNode = {
  image: ImageInterface;
  textBody: readonly Record<string, unknown>[];
  textAlignment: string;
  year: string;
  subHeadline: string;
};

type FAQBlockNode = {
  faqItems: FaqItem[];
  heading?: string;
};

type EnumeratedContentNode = {
  numberItems: BlockItem[];
  heading?: string;
};

type RichImageNode = {
  image: ImageInterface;
  _type: string;
};

type MarkProps = {
  children: ReadonlyArray<string>;
  mark: {
    _key: string;
    _type: string;
    reference?: Record<string, unknown>;
    href?: string;
  };
  markKey: string;
  _key: string;
  _type: string;
};

type YouTubeVideoNode = {
  _key: string;
  _type: string;
  coverImage: ImageInterface;
  video: string;
  text: readonly Record<string, unknown>[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderCenterText = (props: { children?: readonly string[]; node: any; options?: Record<string, unknown> }) => {
  return props.node.children[0].marks?.includes("center") ? "centerArticleText" : undefined;
};

const getId = (props: any) => {
  let id = "";
  props.children
    .filter((text: any) => text)
    .map((text: any) => {
      if (typeof text === "string" && text) {
        id += text;
      } else {
        id += text?.props?.node?.children.map((textId: string) => textId).join("") || "";
      }
    });
  return id.trim();
};

export const RichTextSerializers = () => {
  const { sanityProductLandingPage } = useContext(LocalizedContext);
  return {
    types: {
      callOut: ({ node }: { node: TextNode }) => {
        return <CallOut text={node.text} />;
      },
      imageBlock: ({ node }: { node: RichImageNode }) => {
        return <RichTextImage data={node.image} />;
      },
      imageTextBlock: ({ node }: { node: ImageTextNode }) => {
        return <ImageTextBlock data={node} />;
      },
      timePeriodBlock: ({ node }: { node: TimePeriodNode }) => {
        return <TimePeriodBlock data={node} />;
      },
      faqs: ({ node }: { node: FAQBlockNode }) => {
        return <FAQBlock faqItems={node.faqItems} heading={node.heading} />;
      },
      faqBlock: ({ node }: { node: FAQBlockNode }) => {
        return <FAQBlock faqItems={node.faqItems} heading={node.heading} />;
      },
      youTubeVideoBlock: ({ node }: { node: YouTubeVideoNode }) => {
        return <YouTubeVideoComponent {...node} />;
      },
      enumeratedContent: ({ node }: { node: EnumeratedContentNode }) => {
        return <EnumeratedBlock numberItems={node.numberItems} heading={node.heading} />;
      },
      multipleImage: ({ node }: { node: MultipleImageNode }) => {
        return <RichTextGallery {...node} />;
      },
      block: (props: any) => {
        const { style = "normal" } = props.node;
        if (style === "blockquote") {
          return (
            <blockquote>
              <p>
                {props.children.map((text: string, index: number) => {
                  return <span key={text + index}>{text}</span>;
                })}
              </p>
            </blockquote>
          );
        }

        if (style === "h2") {
          const id = getId(props);
          return (
            <div className={renderCenterText(props)}>
              <h2 id={id}>
                {props.children.map((text: string) => {
                  return text;
                })}
              </h2>
            </div>
          );
        }

        if (style === "h3") {
          return (
            <h3 className={renderCenterText(props)}>
              {props.children.map((text: string) => {
                return text;
              })}
            </h3>
          );
        }

        if (style === "normal") {
          return (
            <p className={renderCenterText(props)}>
              {props.children.map((text: string, index: number) => {
                return <span key={text + index}>{text}</span>;
              })}
            </p>
          );
        }

        return BlockContent.defaultSerializers.types.block(props);
      }
    },
    marks: {
      colorlist: (props: MarkProps) => {
        if (props.mark._type === "colorlist") {
          props.children.map((text, index) => {
            return <span key={text + index}>{text}</span>;
          });
        }
        return null;
      },
      center: (props: MarkProps) => {
        if (props.markKey === "center") {
          return props.children.map((text, index) => {
            return <span key={text + index}>{text}</span>;
          });
        }
        return null;
      },
      sup: (props: MarkProps) => {
        if (props.markKey === "sup") {
          return props.children.map((text, index) => {
            return <sup key={text + index}>{text}</sup>;
          });
        }
        return null;
      },
      internalLink: (props: MarkProps) => {
        if (props.mark._type === "internalLink") {
          return props.children.map((text, index) => {
            return (
              <Link
                _id={props.mark.reference?._id as string}
                to={`/${
                  props.mark.reference?._type === "product" || props.mark.reference?._type === "productLinePage"
                    ? sanityProductLandingPage?.slug.current
                    : (
                        (props.mark.reference?.parentSection as Record<string, unknown>)?.slug as Record<
                          string,
                          unknown
                        >
                      )?.current
                }/${(props.mark.reference?.slug as Record<string, unknown>)?.current as string}`}
                key={text + index}
              >
                {text}
              </Link>
            );
          });
        }
      },
      link: (props: MarkProps) => {
        if (props.mark._type === "link") {
          return props.children.map((text, index) => {
            return (
              <>
                <ExternalLink
                  link={props.mark.href as string}
                  name={text}
                  noopener={true}
                  nofollow={false}
                  key={(props?.mark?.href as string) + index}
                  markKey={""}
                />
              </>
            );
          });
        }
      },
      product: (props: MarkProps) => {
        if (props.mark._type === "product") {
          return props.children.map((text, index) => {
            return (
              <ProductLink
                key={text + index}
                name={text}
                link={`${((props.mark.reference?.parentPage as Record<string, unknown>)?.slug as Record<string, unknown>)?.current as string}/${props.mark.reference?.paRetailerCategory?.toLowerCase().split(' ').join('-')}/${(props.mark.reference?.paSlug as Record<string, unknown>)?.current as string}`}
              >
                {text}
              </ProductLink>
            );
          });
        }
      }
    }
  };
};

export const HtmlTextSerializers = {
  types: {
    block: ({ children }: any) => {
      return (
        <span>
          {children.map((child: any, idx: number) => {
            if (typeof child === "string") {
              return <span key={idx} dangerouslySetInnerHTML={{ __html: child.replace(/pre>/gi, "div>") }} />;
            } else {
              return child;
            }
          })}
        </span>
      );
    }
  },
  listItem: ({ children }: any) => {
    return children.filter((c: any) =>
      typeof c === "string" ? !["</pre>", "<pre>"].includes(String(c)?.toLowerCase()) : true
    );
  }
};
