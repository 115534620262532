import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { GatsbyLinkProps } from "gatsby-link";
import classNames from "classnames";
import { FunctionComponent } from "react";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import "./styles.scss";
// We omit the "ref" property for this reason: https://github.com/gatsbyjs/gatsby/issues/16682#issuecomment-718155902
export interface LinkInterface extends Omit<GatsbyLinkProps<Record<string, unknown>>, "ref"> {
  _id?: string;
  asButton?: string;
  type?: string;
  tabIndex?: number;
}

export const Link: FunctionComponent<LinkInterface> = ({
  _id,
  children,
  to,
  asButton,
  className,
  type = "internal",
  tabIndex = 0,
  ...other
}) => {
  if (type !== "internal") {
    return (
      <a
        href={to}
        tabIndex={tabIndex}
        {...other}
        className={classNames(`${asButton ? `c-link__button c-link__button--${asButton}` : ""}`, className)}
      >
        {children}
      </a>
    );
  }
  const localizedPath = getLocalizedPath(to, _id);
  return (
    <GatsbyLink
      partiallyActive={true}
      activeClassName="active"
      to={localizedPath}
      tabIndex={tabIndex}
      {...other}
      className={classNames(`${asButton ? `c-link__button c-link__button--${asButton}` : ""}`, className)}
    >
      {children}
    </GatsbyLink>
  );
};
