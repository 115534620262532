import React, { FunctionComponent } from "react";
import "./styles.scss";

export interface ButtonInterface extends React.ComponentPropsWithoutRef<"button"> {
  variant: string;
}

const Button: FunctionComponent<ButtonInterface> = (props: ButtonInterface) => {
  const { variant, className, children, ...rest } = props;
  const btnClass = `button-${variant} rounded-0 ${className}`;
  return (
    <button type="button" {...rest} className={btnClass}>
      {children}
    </button>
  );
};

export default Button;
