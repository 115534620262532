import React, { FunctionComponent } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import ContextAwareAccordionToggle from "../ContextAwareAccordionToggle";
import "./styles.scss";

export interface EnumeratedBlockInterface {
  numberItems: BlockItem[];
  heading?: string;
}

export type BlockItem = {
  title: string;
  _rawContent?: Record<string, unknown>;
  content?: any;
  _key: string;
};

const EnumeratedBlock: FunctionComponent<EnumeratedBlockInterface> = ({ numberItems }) => {
  
  const renderItems = () => {
    return numberItems.map((item, index) => {
      return (
        <div key={index} class="enumerated-block" data-testid="enumerated-block" aria-label={item.title}>
          <div class="count">{index + 1}</div>
          <div>
            <h3>{item.title}</h3>
            <BlockContent blocks={item._rawContent || item.content} />
          </div>
        </div>
      );
    });
  };

  return (
    <div data-testid="enumerated-content" className="enumerated-content">
      {renderItems()}
    </div>
  );
};

export default EnumeratedBlock;
