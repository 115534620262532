import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { UpArrow } from "../../images/icons/upArrow";
import Accessibility from "../Accessibility";
import Button from "../Button";
import "./styles.scss";
import { LocalizedContext } from "../../services/LocalizedContextService";
import { event66 } from "../../analytics/event66";
import { smoothScroll } from "../../utils/helper";

const BackToTop: FunctionComponent = () => {
  const [sticky, setSticky] = useState("");
  const { backToTop } = useContext(LocalizedContext).sanityAccessibility || {};

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
    };
  });

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 250 ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event66(backToTop ? backToTop : "back to top");
    smoothScroll(0).then(() => {
      // const firstFocusableElement = document.body.querySelectorAll(
      //   'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      // )[0] as HTMLElement;
      // firstFocusableElement.focus();
    });
  };

  const classes = `back-to-top text-right flex-grow-1 ${sticky}`;

  return (
    <section className={classes} data-testid="back-to-top">
      <Button
        className="border border-dark d-flex justify-content-center align-items-center font-weight-bold"
        variant="primary"
        type="button"
        onClick={handleClick}
      >
        <span>To top</span> <UpArrow />
        <Accessibility location={backToTop} />
      </Button>
    </section>
  );
};

export default BackToTop;
